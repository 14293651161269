export const isDashBoard = ''
export const isAdmin = '/admin/dashboard'

export const pageurl = {
  HOME: '/',
  REGISTER: '/auth/signup',
  LOGIN: '/auth/login',
  ACTIVATEACCOUNT: '/auth/activate',
  FORGOTPASSWORD: '/auth/forgot-password',
  RESETPASSWORD: '/auth/reset-password',
  SUSPENDED: `/suspended`,
  PUBLICFUNANDGAMES: `/fun-and-games`,

  VENDOR: '/tipsters',
  TIPS: '/tips',
  BUYER: '/buyer',
  ANALYTICS: '/analytics',
  TC: '/terms-and-condition',
  PP: '/privacy-policy',
  SITEMAP: '/site-map',
  SUBSCRIPTIONPLAN: '/subscription-plan',
  PAYMENTSTATUS: '/payment-status',
  // banner
  BANNERADPREVIEW: `/banner-ad/preview`,

  ONBOARDING: '/onboarding',

  BETTICKETS: `/tips`,

  DASHBOARD: `/overview`,
  BETCHANNEL: `/channels`,
  WELCOME: `/welcome`,
  LEADERSHIP: `/leadership-table`,
  SINGLETIP: `/single-tip`,
  BETSCHEDULE: `/bet-schedule`,
  PROFILE: `/profile`,
  NOTIFICATION: `/notification`,
  COMMUNITYFORUM: `/community-forum`,
  FUNNGAMES: `/fun-and-games`,
  ACHIEVEMENTS: `/achievements-and-rewards`,
  MYACCOUNT: `/account`,
  MYACCOUNTPROFILE: `/account/profile`,
  OPPORTUNITIES: `/open-jobs`,
  SCHEDULE: `/schedule`,

  INSTRUCTIONS: '/instructions',
  FAQ: '/faq',
  UNDER_CONSTRUCTION: '/under-construction',
  PAGE404: '/page-404',

  // admin
  ADMINDASHBOARD: `${isAdmin}/overview`,
  ADMINBETCHANNELS: `${isAdmin}/channels`,
  ADMINBETTIPS: `${isAdmin}/tips`,
  ADMINMANAGEMENT: `${isAdmin}/management`,
  ADMINUSERS: `${isAdmin}/users`,
  ADMINSETTINGS: `${isAdmin}/settings`,
  ADMINTRANSACTIONS: `${isAdmin}/transactions`,
  ADMINSUBSCRIPTIONS: `${isAdmin}/subscriptions`,
  ADMINCOMMUNITYFORUM: `${isAdmin}/community-forum`,
  ADMINEARNINGS: `${isAdmin}/earnings`,
  ADMINREPORTS: `${isAdmin}/report-review`,
  ADMINVERIFICATIONREQUEST: `${isAdmin}/verification-request`,
  ADMINFEEDBACK: `${isAdmin}/feed-back`,
  ADMINSENDMESSAGE: `${isAdmin}/send-message`
}
