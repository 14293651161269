const optimizeImg = `f_webp,q_auto:low/`
const optimizeVid = `f_webm,f_auto:video/`

export const gallery = {
  aboutFrame: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380011/about-frame_zodq0o.jpg`,
    alt: ``
  },
  collaborate: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380147/collaborate_tlpre4.jpg`,
    alt: ``
  },
  community: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380237/community_kbmgsz.jpg`,
    alt: ``
  },
  conclusion: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380281/conclusion_bh6nqo.jpg`,
    alt: ``
  },
  fastestFingers: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380346/fastest-fingers_to7vxw.jpg`,
    alt: ``
  },
  home: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380493/home_wtfdcn.png`,
    alt: ``
  },
  jumbo: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380543/jumbo_orkpzh.jpg`,
    alt: ``
  },
  login: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380600/login_prdkyo.jpg`,
    alt: ``
  },
  onYourMarks: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380676/on-your-marks_xnv1ae.jpg`,
    alt: ``
  },
  predictNWin: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380736/predict-n-win_dla1z2.jpg`,
    alt: ``
  },
  signin: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380805/signin_ft15ab.jpg`,
    alt: ``
  },
  spinNGrab: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380851/spin-n-grab_ljspy0.jpg`,
    alt: ``
  },
  thumbsUp: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380898/thumbsup_jxagjc.png`,
    alt: ``
  },
  vendorJumbo: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711380956/vendor-jumbo_qphwcm.png`,
    alt: ``
  },
  vendorPositivity: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711381015/vendor-positivity_qgg5yo.jpg`,
    alt: ``
  },
  welcomeNoBg: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711381080/welcome-nobg_u9mbla.jpg`,
    alt: ``
  },
  welcome: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711381127/welcome_eaopsq.jpg`,
    alt: ``
  },
  qa_addFile: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382726/add-file_cbimmz.png`,
    alt: `dd file`
  },
  qa_badge: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382750/badge_l9qqm2.png`,
    alt: `badge`
  },
  qa_calendar: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382770/calendar_wja4k8.png`,
    alt: `calendar`
  },
  qa_controller: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382788/controller_owprej.png`,
    alt: `controller`
  },
  qa_earning: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382814/earning_eovjfv.png`,
    alt: `earning`
  },
  qa_edit: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382833/edit_ixuquc.png`,
    alt: `edit`
  },
  qa_follow: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382852/follow_xntmyi.png`,
    alt: `follow`
  },
  qa_gear: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382870/gear_oe6oez.png`,
    alt: `gear`
  },
  qa_idea: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382893/idea_z4qrxt.png`,
    alt: `bulb idea`
  },
  qa_internet: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382914/internet_e4dcj6.png`,
    alt: `internet`
  },
  qa_onlineGame: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382935/online-game_nl0d5x.png`,
    alt: `online game`
  },
  qa_plus: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382953/plus_hewbha.png`,
    alt: `plus`
  },
  qa_search: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382977/search_n4bimi.png`,
    alt: `search`
  },
  qa_verifiedAccount: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711382999/verified-account_rnlsqc.png`,
    alt: `verified account`
  },
  qa_wallet: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1711383017/wallet_bupbtx.png`,
    alt: `wallet`
  },
  partnership: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1713459840/pexels-edmond-dantes-4342493-min_rwsicj.jpg`,
    alt: `partner`
  },
  logoicon: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1710642708/logoicon_aoozwu.svg`,
    alt: `partner`
  },
  analytics: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1715722982/analytics_yf6bfz.svg`,
    alt: `analytics`
  },
  risk: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1715723133/risk_usp7v5.svg`,
    alt: `risk`
  },
  logoText: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1715724096/logotext_tz2obn.svg`,
    alt: `risk`
  },
  logoTextWhite: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1715724155/logowhite_wh1qox.svg`,
    alt: `risk`
  },
  logoWhite: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1715724243/logowhite_pq6x1i.svg`,
    alt: `risk`
  },
  logoIcon: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1715724284/logoicon_xbf3hb.svg`,
    alt: `risk`
  },
  logo: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1715724329/logo_xqk9xn.svg`,
    alt: `risk`
  },
  timeTable: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1715725315/time-table_rjmiwc.svg`,
    alt: `minimizing risk on mytipster.pro`
  },
  landingpagestats: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1718702248/stats_okuswt.png`,
    alt: `stats on mytipster.pro`
  },
  landingpagebettips: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1718702237/bettips_jl3lkg.png`,
    alt: `bettips on mytipster.pro`
  },
  landingpagesolution: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1718702232/solution1_og39bx.png`,
    alt: `bet solutions on mytipster.pro`
  },
  landingpagestartwinning: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1718702219/start-winning_vwfhpz.jpg`,
    alt: `start-winning on mytipster.pro`
  },
  landingpageconflict: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1718702219/conflict_tbq1p9.jpg`,
    alt: `conflict`
  },
  landingpagejumbotron: {
    src: `https://res.cloudinary.com/mytipster/video/upload/${optimizeVid}v1718702266/fans_g0zlsb.mp4`,
    alt: `landing page on mytipster.pro`
  },
  gotoplace: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1720366802/Triumphant_Athlete_Celebrating_Victory_1_qwlmdt.jpg`,
    alt: `go to place on mytipster.pro`
  },
  propfirm: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1720841399/Business_Presentation_in_Modern_Office_1_nztv79.jpg`,
    alt: `propfirm on mytipster.pro`
  },
  futbol: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1721150633/Red_Football_Player_in_Pre-Snap_Focus_1_pb7xdm.jpg`,
    alt: `futbol on mytipster.pro`
  },
  fierykick: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1721182259/Fiery_Kick_-_Soccer_Player_in_Action_1_1_y3kcwr.jpg`,
    alt: `fierykick on mytipster.pro`
  },
  laliga: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722282531/la_liga_thv8qb.svg`,
    alt: `la liga`
  },
  ligue1: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722282530/ligue_1_kwhpdz.png`,
    alt: `ligue 1`
  },
  bundesliga: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722282530/bundesliga_rv6ew7.png`,
    alt: `bundesliga`
  },
  premiership: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722282530/english_premiersip_ts4zab.png`,
    alt: `premiership`
  },
  seriaa: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722282530/seria_a_eiqist.png`,
    alt: `seriaa`
  },
  casuals: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722283977/casuals_pmkoq9.png`,
    alt: `casuals`
  },
  casualsbg: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1722548371/Tennis_Chic_Elegance_icjsud.jpg`,
    alt: `casuals`
  },
  conservatives: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722283974/conservatives_hhymxp.png`,
    alt: `conservatives`
  },
  conservativesbg: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1722548483/The_Dapper_Batsman_zkzxvz.jpg`,
    alt: `conservatives`
  },
  risktakers: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722283974/risktakers_umrrgk.png`,
    alt: `risktakers`
  },
  risktakersbg: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1722548293/Airborne_Duel_at_the_Hoop_ictpcn.jpg`,
    alt: `risktakers`
  },
  profitables: {
    src: `https://res.cloudinary.com/mytipster/image/upload/v1722283974/profitables_xksws4.png`,
    alt: `profitables`
  },
  profitablesbg: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1722548432/Triumphant_Tennis_Player_pqlnwp.jpg`,
    alt: `profitables`
  },
  undecidedbg: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1722578111/Aerial_Intersection_Dynamics_1_elsclt.jpg`,
    alt: `profitables`
  },
  avatarbg: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1722583474/Mystical_Tarot_Card_in_Forest_1_cktru8.jpg`,
    alt: `profitables`
  },
  underconstruction: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1722633713/Model_of_Industrial_Scaffolding_Structure_1_mtlugx.jpg`,
    alt: `under construction`
  },
  soccerplaya: {
    src: `https://res.cloudinary.com/mytipster/image/upload/${optimizeImg}v1723042202/Monochrome_Majesty_of_Basketball_1_w4ith5.jpg`,
    alt: `under construction`
  }
}
